import type { GrpcMeta } from './grpc-meta.js';
import type { GrpcMetaValue } from './grpc-meta-value.js';

export class GrpcContext {
  static readonly EMPTY = new GrpcContext(null, []);

  private readonly parent: GrpcContext | null;
  private readonly values: GrpcMetaValue<unknown>[];

  private constructor(
    parent: GrpcContext | null,
    values: GrpcMetaValue<unknown>[],
  ) {
    this.parent = parent ?? null;
    this.values = values;
  }

  *getValues<T>(key: GrpcMeta<T>): IterableIterator<T> {
    for (const metaValue of this.values) {
      if (metaValue.is(key)) {
        yield metaValue.value;
      }
    }

    if (this.parent !== null) {
      yield* this.parent.getValues(key);
    }
  }

  nest(values: GrpcMetaValue<unknown>[]): GrpcContext {
    return new GrpcContext(this, values);
  }
}
