import type { EqualFn } from '../guard/equal-fn.js';

export function isEqualArrays<T>(
  a: readonly T[],
  b: readonly T[],
  equal: EqualFn<T>,
): boolean {
  if (a.length !== b.length) {
    return false;
  }

  for (let index = 0; index < a.length; index++) {
    if (!equal(a[index], b[index])) {
      return false;
    }
  }

  return true;
}
