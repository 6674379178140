import type {
  DescMethod,
  MessageInitShape,
  MessageShape,
} from '@bufbuild/protobuf';
import { create } from '@bufbuild/protobuf';
import type { Observable } from 'rxjs';
import { concatMap, EMPTY, of, throwError } from 'rxjs';

import { GrpcContext } from './grpc-context.js';
import { GrpcMessageValue } from './grpc-message-value.js';
import type { GrpcMetaValue } from './grpc-meta-value.js';
import type { GrpcValue } from './grpc-value.js';

export abstract class GrpcTransport {
  abstract lowLevelSummon<M extends DescMethod>(
    method: M,
    input: NoInfer<MessageShape<M['input']>>,
    context: GrpcContext,
  ): Observable<GrpcValue<M['output']>>;

  summon<M extends DescMethod>(
    method: M,
    input: NoInfer<MessageInitShape<M['input']>>,
    context: GrpcMetaValue<unknown>[] = [],
  ): Observable<MessageShape<M['output']>> {
    let inputMessage: MessageShape<M['input']>;

    try {
      inputMessage = create<M['input']>(method.input, input);
    } catch (error) {
      return throwError(() => error);
    }

    return this.lowLevelSummon<M>(
      method,
      inputMessage,
      GrpcContext.EMPTY.nest(context),
    ).pipe(
      concatMap((next) =>
        next instanceof GrpcMessageValue ? of(next.message) : EMPTY,
      ),
    );
  }
}
