import type { DepartmentGroup, LicenseGroup } from '#gen/env';

const GROUP_REGEX =
  /^(?<department>\w+).(?<license>\w+).(?<environment>\w+).(?<position>\w+).(?<unused0>\w+)$/;

export class AccessGroup {
  static parse(group: string): AccessGroup {
    // eslint-disable-next-line ts/no-unsafe-type-assertion
    const result = GROUP_REGEX.exec(group)?.groups as
      | Record<keyof AccessGroup, string>
      | undefined;

    if (result === undefined) {
      throw new TypeError(`Incompatible group ${group}`);
    }

    return new AccessGroup(
      // eslint-disable-next-line ts/no-unsafe-type-assertion
      result.department as DepartmentGroup,
      // eslint-disable-next-line ts/no-unsafe-type-assertion
      result.license as LicenseGroup,
      result.environment,
      result.position,
      result.unused0,
    );
  }

  static supports(
    departments: ReadonlySet<DepartmentGroup>,
    license: LicenseGroup,
    groups: readonly AccessGroup[],
  ): boolean {
    return groups.some((group) => group.supports(departments, license));
  }

  readonly department: DepartmentGroup;
  readonly license: LicenseGroup;
  readonly environment: string;
  readonly position: string;
  readonly unused0: string;

  // eslint-disable-next-line ts/max-params
  private constructor(
    department: DepartmentGroup,
    license: LicenseGroup,
    environment: string,
    position: string,
    unused0: string,
  ) {
    this.department = department;
    this.license = license;
    this.environment = environment;
    this.position = position;
    this.unused0 = unused0;
  }

  supports(
    departments: ReadonlySet<DepartmentGroup>,
    license: LicenseGroup,
  ): boolean {
    return departments.has(this.department) && license === this.license;
  }
}
