import { inject, InjectionToken } from '@angular/core';

import type { GrpcTransport } from '@up/grpc';
import { LoggerTransport } from '@up/grpc';
import { FetchTransport } from '@up/grpc-fetch';

import { ENV_ORIGIN } from './env-origin.js';

export const ENV_TRANSPORT = new InjectionToken<GrpcTransport>(
  'ENV_TRANSPORT',
  {
    providedIn: 'root',
    factory: () => new LoggerTransport(new FetchTransport(inject(ENV_ORIGIN))),
  },
);
