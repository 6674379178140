export function decodeTrailer(bytes: Uint8Array): Headers {
  const headers = new Headers();
  const lines = new TextDecoder().decode(bytes).split('\r\n');

  for (const line of lines) {
    const SEPARATOR = ':';
    const index = line.indexOf(SEPARATOR);

    if (index <= 0) {
      continue;
    }

    headers.append(
      line.slice(0, index).trim(),
      line.slice(index + SEPARATOR.length),
    );
  }

  return headers;
}
