import type { EnumDefinition } from './enum-definition.js';
import { enumValueToKeys } from './enum-value-to-keys.js';

export function enumValueToFirstKey<
  K extends string,
  V extends number | string,
>(definition: EnumDefinition<K, V>, value: NoInfer<V>): K {
  for (const key of enumValueToKeys<K, V>(definition, value)) {
    return key;
  }

  throw new Error('key not found');
}
