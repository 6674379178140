@if (_signIn.state$ | async; as state) {
  @switch (state.type) {
    @case ('not-exist') {
      <h1>Department does not exist</h1>

      <p>Possible causes of the problem:</p>
      <ul>
        <li>Incorrect URL.</li>
        <li>Missing department.</li>
        <li>Incorrect server settings.</li>
      </ul>
      <footer>
        <a class="button" [href]="_prefix + '/'">Go to Root</a>
      </footer>
    }

    @case ('unauthorized') {
      <h1>Welcome to our platform</h1>

      @if (state.rollback !== null) {
        @switch (state.rollback.type) {
          @case ('unavailable') {
            <a class="button" [href]="_prefix + '/'">
              Forget {{ state.rollback.front.baseTitle }}
            </a>
          }
          @case ('invalid') {
            <a class="button" [href]="_prefix + '/'">Forget invalid url</a>
          }
        }
      }

      <google-sign-in-button />
    }

    @case ('authorized') {
      <div class="user">
        <img class="avatar" [src]="state.picture" alt="Avatar" />
        <div class="full-name">{{ state.firstName }} {{ state.lastName }}</div>
        <div class="email">{{ state.email }}</div>
      </div>

      <h2>Available departments:</h2>
      <ul>
        @for (front of state.fronts; track front.defaultUrl) {
          <li>
            <a [href]="_prefix + front.defaultUrl">{{ front.baseTitle }}</a>
          </li>
        }
      </ul>

      @if (state.rollback !== null) {
        @switch (state.rollback.type) {
          @case ('available') {
            <button
              class="button"
              (click)="_signIn.goToRollBack(state.rollback.url)"
              type="button"
            >
              Restore {{ state.rollback.front.baseTitle }}
            </button>
          }
          @case ('unavailable') {
            <h2>Access denied:</h2>
            <p>{{ state.rollback.front.baseTitle }}</p>
            <a class="button" [href]="_prefix + '/'">
              Forget {{ state.rollback.front.baseTitle }}
            </a>
          }
          @case ('invalid') {
            <h2>Invalid url</h2>
            <pre>{{ state.rollback.url }}</pre>
            <a class="button" [href]="_prefix + '/'">Forget invalid url</a>
          }
        }
      }
      <button class="button" (click)="_signIn.logOut()" type="button">
        Log out
      </button>
    }

    @case ('error') {
      <h1>Error</h1>
      <pre class="error">{{ state.error }}</pre>
    }

    @case ('redirection') {
      <h1>Redirection…</h1>
      <p>{{ state.front.baseTitle }}</p>
    }
  }
}
