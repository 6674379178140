import { enumValueToFirstKey } from '@up/utils';

import { Code } from '#gen/code';

export class GrpcError extends Error {
  override readonly name: string = 'GrpcError';
  readonly code: Code;

  constructor(code: Code, message?: string) {
    super(message);

    this.code = code;
  }

  toStatusString(): string {
    return enumValueToFirstKey(Code, this.code);
  }
}
