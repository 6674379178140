import type { Envelope } from './envelope.js';
import { ENVELOPE_HEADER_BYTE_LENGTH } from './envelope-header-byte-length.js';

export function toEnvelopeChunk({ flags, bytes }: Envelope): Uint8Array {
  const chunk = new Uint8Array(bytes.byteLength + ENVELOPE_HEADER_BYTE_LENGTH);
  const view = new DataView(chunk.buffer, chunk.byteOffset, chunk.byteLength);

  view.setUint8(0, flags);
  view.setUint32(1, bytes.byteLength, false);

  chunk.set(bytes, ENVELOPE_HEADER_BYTE_LENGTH);

  return chunk;
}
