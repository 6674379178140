import { mergeApplicationConfig } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import { loadEnvConfig } from '@up/env';

import { appConfig } from './app.config.js';
import { baseConfig } from './app.config-base.js';
import { AppLoginComponent } from './app/app-login.component.js';

await bootstrapApplication(
  AppLoginComponent,
  mergeApplicationConfig(await loadEnvConfig(), baseConfig, appConfig),
);
