import type { Code } from '@up/grpc';
import { GrpcError } from '@up/grpc';

import { fromHttpStatus } from './from-http-status.js';

export class GrpcStatusHttpError extends GrpcError {
  static assert(status: number, message?: string): void {
    // eslint-disable-next-line ts/no-magic-numbers -- TODO extract to guard
    if (status >= 200 && status < 300) {
      return;
    }

    throw GrpcStatusHttpError.from(status, message);
  }

  static from(status: number, message?: string): GrpcStatusHttpError {
    return new GrpcStatusHttpError(status, message);
  }

  override readonly name: string = 'GrpcStatusHttpError';

  readonly status: number;

  private constructor(status: Code, message?: string) {
    super(fromHttpStatus(status), message);

    this.status = status;
  }

  override toStatusString(): string {
    return `${super.toStatusString()} status: ${this.status}`;
  }
}
