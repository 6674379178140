/* eslint-disable ts/naming-convention */

import type { EnvironmentProviders } from '@angular/core';
import {
  inject,
  INJECTOR,
  makeEnvironmentProviders,
  provideEnvironmentInitializer,
} from '@angular/core';

import { callbackInjectionContext } from '@up/ng';

import { GOOGLE_SIGN_IN_CLIENT_ID } from './google-sign-in-client-id.js';

export function provideGoogleSignIn(
  config: Omit<google.accounts.id.IdConfiguration, 'client_id'> = {},
): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideEnvironmentInitializer(() => {
      const injector = inject(INJECTOR);

      google.accounts.id.initialize({
        ...config,
        client_id: inject(GOOGLE_SIGN_IN_CLIENT_ID),
        callback: callbackInjectionContext(injector, config.callback),
        native_callback: callbackInjectionContext(
          injector,
          config.native_callback,
        ),
        intermediate_iframe_close_callback: callbackInjectionContext(
          injector,
          config.intermediate_iframe_close_callback,
        ),
      });
    }),
  ]);
}
