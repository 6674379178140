import type { SubjectLike, Subscriber } from 'rxjs';
import { Observable } from 'rxjs';

interface Cache<T> {
  readonly serialized: string;
  readonly value: T;
}

// TODO external Storage instance
// TODO use STORAGE_EVENT
export abstract class LocalStorageSubject<T, D>
  extends Observable<T | D>
  implements SubjectLike<T | D>
{
  readonly #key: string;

  readonly #defaultValue: D;

  readonly #subscribers = new Set<Subscriber<T | D>>();

  #cache: Cache<T> | null = null;

  protected constructor(key: string, defaultValue: D) {
    super((subscriber) => {
      subscriber.next(this.getValue());

      if (subscriber.closed) {
        return;
      }

      if (this.#subscribers.size === 0) {
        window.addEventListener('storage', this.#onStorage);
      }

      this.#subscribers.add(subscriber);

      subscriber.add(() => {
        this.#subscribers.delete(subscriber);

        if (this.#subscribers.size === 0) {
          window.removeEventListener('storage', this.#onStorage);
        }
      });
    });

    this.#key = key;
    this.#defaultValue = defaultValue;
  }

  // TODO simplify
  readonly #onStorage = (event: StorageEvent): void => {
    if (
      event.key === null
        ? this.#cache === null
        : event.key !== this.#key ||
          (event.newValue === null && this.#cache === null)
    ) {
      return;
    }

    let value: T | D;

    if (event.key === null) {
      this.#cache = null;
      value = this.#defaultValue;
    } else if (event.newValue === null) {
      this.#cache = null;
      value = this.#defaultValue;
    } else if (
      this.#cache !== null &&
      this.#cache.serialized === event.newValue
    ) {
      ({ value } = this.#cache);
    } else {
      try {
        value = this._parse(event.newValue);
        this.#cache = { serialized: event.newValue, value };
      } catch (error) {
        console.error(error);
        this.#cache = null;
        value = this.#defaultValue;
      }
    }

    for (const subscriber of this.#subscribers) {
      subscriber.next(value);
    }
  };

  #broadcastNext(value: T | D): void {
    for (const subscriber of this.#subscribers) {
      subscriber.next(value);
    }
  }

  protected abstract _parse(serialized: string): T;

  protected abstract _stringify(value: T): string;

  protected abstract _equal(a: T, b: T): boolean;

  protected abstract _isDefault(value: T | D): value is D;

  nextDefault(): void {
    if (this.#cache !== null) {
      this.#cache = null;
      this.#broadcastNext(this.#defaultValue);
    }

    localStorage.removeItem(this.#key);
  }

  next(value: T | D): void {
    if (this._isDefault(value)) {
      this.nextDefault();

      return;
    }

    let serialized: string;

    try {
      serialized = this._stringify(value);
    } catch (error) {
      console.error(error);
      this.nextDefault();
      return;
    }

    if (this.#cache === null || !this._equal(this.#cache.value, value)) {
      this.#cache = { serialized, value };
      this.#broadcastNext(value);
    }

    localStorage.setItem(this.#key, serialized);
  }

  error(error: unknown): void {
    console.error(error);
    this.nextDefault();
  }

  complete(): void {
    // there is no need to do anything here
  }

  getValue(): T | D {
    if (this.#subscribers.size !== 0) {
      return this.#cache === null ? this.#defaultValue : this.#cache.value;
    }

    const serialized: string | null = localStorage.getItem(this.#key);

    if (serialized === null) {
      this.#cache = null;
      return this.#defaultValue;
    }

    if (this.#cache === null || this.#cache.serialized !== serialized) {
      try {
        this.#cache = { serialized, value: this._parse(serialized) };
      } catch (error) {
        console.error(error);
        this.#cache = null;
        return this.#defaultValue;
      }
    }

    return this.#cache.value;
  }
}
