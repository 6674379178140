import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  ViewEncapsulation,
} from '@angular/core';

import { FEATURE_PREFIX } from '@up/env';
import { GoogleSignInButtonComponent } from '@up/google';

import { SignInService } from './sign-in.service.js';

@Component({
  selector: 'app-login',
  imports: [AsyncPipe, GoogleSignInButtonComponent],
  templateUrl: './app-login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AppLoginComponent {
  protected readonly _signIn = inject(SignInService);
  protected readonly _prefix = inject(FEATURE_PREFIX);
}
