import type { Injector } from '@angular/core';
import { runInInjectionContext } from '@angular/core';

export function callbackInjectionContext<P extends unknown[]>(
  injector: Injector,
  callback: (...params: P) => void,
): (...params: P) => void;
export function callbackInjectionContext<P extends unknown[]>(
  injector: Injector,
  callback: ((...params: P) => void) | undefined,
): ((...params: P) => void) | undefined;
export function callbackInjectionContext<P extends unknown[]>(
  injector: Injector,
  callback: ((...params: P) => void) | undefined,
): ((...params: P) => void) | undefined {
  if (callback === undefined) {
    return undefined;
  }

  return (...params) => {
    runInInjectionContext(injector, () => {
      callback(...params);
    });
  };
}
