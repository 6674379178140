import type { DescMessage, MessageShape } from '@bufbuild/protobuf';
import { fromBinary } from '@bufbuild/protobuf';
import { base64Decode } from '@bufbuild/protobuf/wire';
import type { Any } from '@bufbuild/protobuf/wkt';
import { anyUnpack } from '@bufbuild/protobuf/wkt';

import { Code } from '#gen/code';
import { StatusSchema } from '#gen/status';
import { GrpcError } from './grpc-error.js';

export class GrpcTrailerError extends GrpcError {
  static assert(trailer: Headers): void {
    const statusBytes = trailer.get('grpc-status-details-bin');

    if (statusBytes !== null) {
      const status = fromBinary(StatusSchema, base64Decode(statusBytes));

      // eslint-disable-next-line ts/no-unsafe-enum-comparison
      if (status.code === Code.OK) {
        return;
      }

      throw new GrpcTrailerError(status.code, status.details, status.message);
    }

    const status = parseInt(trailer.get('grpc-status') ?? '0', 10);

    // eslint-disable-next-line ts/no-unsafe-enum-comparison
    if (status === Code.OK) {
      return;
    }

    throw new GrpcError(status, trailer.get('grpc-message') ?? '');
  }

  override readonly name: string = 'GrpcTrailerError';

  readonly #details: readonly Any[];

  constructor(code: Code, details: readonly Any[], message?: string) {
    super(code, message);
    this.#details = details;
  }

  getDetails<D extends DescMessage>(schema: D): MessageShape<D> | null {
    for (const detail of this.#details) {
      let message = anyUnpack<D>(detail, schema);

      if (message !== undefined) {
        return message;
      }
    }

    return null;
  }
}
