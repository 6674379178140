/* eslint-disable ts/naming-convention */

import {
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  inject,
  input,
  LOCALE_ID,
  output,
  ViewEncapsulation,
} from '@angular/core';

import { GOOGLE_SIGN_IN_BUTTON_DEFAULT } from './google-sign-in-button-default.js';

@Component({
  selector: 'google-sign-in-button',
  standalone: true,
  template: '',
  styleUrl: './google-sign-in-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'google-sign-in-button' },
})
export class GoogleSignInButtonComponent {
  readonly #nativeElement =
    inject<ElementRef<HTMLElement>>(ElementRef).nativeElement;

  readonly type = input<google.accounts.id.GsiButtonConfiguration['type']>();

  readonly theme =
    input<NonNullable<google.accounts.id.GsiButtonConfiguration['theme']>>();

  readonly size =
    input<NonNullable<google.accounts.id.GsiButtonConfiguration['size']>>();

  readonly text =
    input<NonNullable<google.accounts.id.GsiButtonConfiguration['text']>>();

  readonly shape =
    input<NonNullable<google.accounts.id.GsiButtonConfiguration['shape']>>();

  readonly logoAlignment =
    input<
      NonNullable<google.accounts.id.GsiButtonConfiguration['logo_alignment']>
    >();

  readonly width =
    input<NonNullable<google.accounts.id.GsiButtonConfiguration['width']>>();

  readonly locale =
    input<NonNullable<google.accounts.id.GsiButtonConfiguration['locale']>>();

  readonly state =
    input<NonNullable<google.accounts.id.GsiButtonConfiguration['state']>>();

  readonly clickListener = output();

  constructor() {
    const locale = inject(LOCALE_ID);
    const config = inject(GOOGLE_SIGN_IN_BUTTON_DEFAULT);

    effect(() => {
      google.accounts.id.renderButton(this.#nativeElement, {
        type: this.type() ?? config.type,
        theme: this.theme() ?? config.theme,
        size: this.size() ?? config.size,
        text: this.text() ?? config.text,
        logo_alignment: this.logoAlignment() ?? config.logo_alignment,
        width: this.width() ?? config.width,
        locale: this.locale() ?? config.locale ?? locale,
        shape: this.shape() ?? config.shape,
        state: this.state() ?? config.state,
        click_listener: () => {
          this.clickListener.emit();
        },
      });
    });
  }
}
