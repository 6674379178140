import type { ApplicationConfig } from '@angular/core';

import type { EnvRootLiteral } from '#gen/env';
import { provideEnv } from './provide-env.js';

export async function loadEnvConfig(): Promise<ApplicationConfig> {
  const link = document.querySelector<HTMLLinkElement>('link[data-env]');

  if (link === null) {
    throw new Error('<link /> with env.json not found');
  }

  const response = await fetch(link.href, {
    priority: 'high',
    credentials: 'include',
    mode: 'no-cors',
  });

  const env: EnvRootLiteral = await response.json();

  // TODO remove when each domain has its own env.json
  if (env.override !== undefined) {
    for (const [hostname, override] of Object.entries(env.override)) {
      if (hostname === location.hostname) {
        return { providers: [provideEnv(override)] };
      }
    }
  }

  return { providers: [provideEnv(env)] };
}
