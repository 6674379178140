import { inject, InjectionToken } from '@angular/core';

import { BASE_HREF } from '@up/ng';

export const FEATURE_PREFIX = new InjectionToken<string>('FEATURE_PREFIX', {
  providedIn: 'root',
  factory: () => {
    const href = inject(BASE_HREF);
    const prefix = href.slice(0, -1);

    if (prefix === '') {
      throw new Error('Incorrect number of segments in app');
    }

    return prefix.split('/').slice(0, -1).join('/');
  },
});
