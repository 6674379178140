import type { DescMessage, MessageShape } from '@bufbuild/protobuf';
import { equals, fromBinary, toBinary } from '@bufbuild/protobuf';
import { base64Decode, base64Encode } from '@bufbuild/protobuf/wire';
import type { Any } from '@bufbuild/protobuf/wkt';
import { AnySchema, anyUnpack } from '@bufbuild/protobuf/wkt';

import type { Jsonable } from '@up/utils';
import {
  assert,
  isArrayWith,
  isEqualArrays,
  isSomeObject,
  isString,
} from '@up/utils';

interface SessionJson {
  readonly access: string;
  readonly refresh: string;
  readonly extras: readonly string[];
}

export class Session implements Jsonable<SessionJson> {
  static equal(a: Session, b: Session): boolean {
    return (
      a.access === b.access &&
      a.refresh === b.refresh &&
      isEqualArrays<Any>(a.#extras, b.#extras, (aItem, bItem) =>
        equals(AnySchema, aItem, bItem),
      )
    );
  }

  static fromJson(value: unknown): Session {
    assert(isSomeObject<SessionJson>(value));
    assert(isString(value.access));
    assert(isString(value.refresh));
    assert(isArrayWith(value.extras, isString));

    return new Session(
      value.access,
      value.refresh,
      value.extras.map<Any>((extra) =>
        fromBinary(AnySchema, base64Decode(extra)),
      ),
    );
  }

  readonly access: string;

  readonly refresh: string;

  readonly #extras: readonly Any[];

  constructor(access: string, refresh: string, extras: Any[]) {
    this.access = access;
    this.refresh = refresh;
    this.#extras = extras;
  }

  getExtra<D extends DescMessage>(schema: D): MessageShape<D> | null {
    for (const detail of this.#extras) {
      let message = anyUnpack<D>(detail, schema);

      if (message !== undefined) {
        return message;
      }
    }

    return null;
  }

  toJSON(): SessionJson {
    return {
      access: this.access,
      refresh: this.refresh,
      extras: this.#extras.map((extra) =>
        base64Encode(toBinary(AnySchema, extra)),
      ),
    };
  }
}
