import type { GrpcMeta } from './grpc-meta.js';

export class GrpcMetaValue<T> {
  readonly key: GrpcMeta<T>;
  readonly value: T;

  constructor(key: GrpcMeta<T>, value: T) {
    this.value = value;
    this.key = key;
  }

  is<K>(key: GrpcMeta<K>): this is GrpcMetaValue<K> {
    return Object.is(this.key, key);
  }
}
