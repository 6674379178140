import { identity } from 'rxjs';

import type { EqualFn, FromJsonFn, GuardFn, ToJsonFn } from '@up/utils';

import type { JsonLocalStorageSubjectOptions } from './json-local-storage-subject-options.js';
import { LocalStorageSubject } from './local-storage-subject.js';

export class JsonLocalStorageSubject<T, D> extends LocalStorageSubject<T, D> {
  readonly #fromJson: FromJsonFn<T>;
  readonly #toJson: ToJsonFn<T>;
  readonly #equal: EqualFn<T>;
  readonly #isDefault: GuardFn<T | D, D>;

  // eslint-disable-next-line ts/max-params
  constructor(
    key: string,
    defaultValue: D,
    fromJson: FromJsonFn<T>,
    {
      toJson = identity,
      equal = Object.is,
      isDefault = (value): value is D => Object.is(value, defaultValue),
    }: JsonLocalStorageSubjectOptions<NoInfer<T>, NoInfer<D>> = {},
  ) {
    super(key, defaultValue);

    this.#fromJson = fromJson;
    this.#toJson = toJson;
    this.#equal = equal;
    this.#isDefault = isDefault;
  }

  protected override _parse(serialized: string): T {
    return this.#fromJson(JSON.parse(serialized));
  }

  protected override _stringify(value: T): string {
    return JSON.stringify(this.#toJson(value));
  }

  protected override _equal(a: T, b: T): boolean {
    return this.#equal(a, b);
  }

  protected override _isDefault(value: T | D): value is D {
    return this.#isDefault(value);
  }
}
