import { InjectionToken } from '@angular/core';

export const BASE_HREF = new InjectionToken<string>('BASE_HREF', {
  providedIn: 'root',
  factory: () => {
    const base = document.querySelector<HTMLBaseElement>('base[href]');

    if (base === null) {
      throw new Error('<base href="…" /> not specified');
    }

    const href = base.getAttribute('href');

    if (href === null || !href.startsWith('/') || !href.endsWith('/')) {
      throw new Error('Invalid href attribute in <base />');
    }

    return href;
  },
});
