import type { EnumDefinition } from './enum-definition.js';
import { enumEntries } from './enum-entries.js';

export function* enumValueToKeys<K extends string, V extends number | string>(
  definition: EnumDefinition<K, V>,
  value: V,
): Iterable<K> {
  for (const [key, item] of enumEntries(definition)) {
    if (item === value) {
      yield key;
    }
  }
}
