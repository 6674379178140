import type { EnvironmentProviders, Provider } from '@angular/core';
import { makeEnvironmentProviders } from '@angular/core';

import { provideMultiValue, provideValue } from '@up/ng';

import type { EnvLiteral } from '#gen/env';
import { ENV_GOOGLE_ID } from './env-google-id.js';
import { ENV_ORIGIN } from './env-origin.js';
import { Front } from './front.js';
import { KNOWN_FRONTS } from './known-envs.js';

// dev (com,ua,kz)
// prod (com,ua)
// └─ prod (kz)
// preprod (com,ua)
// └─ preprod (kz)
// wl dev (com)
// wl prod (com)
// wl preprod (com)

export function provideEnv({
  origin,
  googleClientId,
  fronts,
  licenses,
}: EnvLiteral): EnvironmentProviders {
  const providers: Provider[] = [
    provideValue(ENV_ORIGIN, origin),
    provideValue(ENV_GOOGLE_ID, googleClientId),
  ];

  for (const front of Front.from(origin, fronts, licenses)) {
    providers.push(provideMultiValue(KNOWN_FRONTS, front));
  }

  return makeEnvironmentProviders(providers);
}
