import type { EnumDefinition } from './enum-definition.js';

// TODO fix type
// TODO init inside enumEntries
const cache = new WeakMap<
  EnumDefinition<string, number | string>,
  ReadonlyMap<string, number | string>
>();

export function enumEntries<K extends string, V extends number | string>(
  definition: EnumDefinition<K, V>,
): ReadonlyMap<K, V> {
  // eslint-disable-next-line ts/no-unsafe-type-assertion
  let result = cache.get(definition) as Map<string, V> | undefined;

  if (result === undefined) {
    result = new Map<string, V>(Object.entries<V>(definition));

    for (const key in definition) {
      if (typeof definition[key] === 'number') {
        result.delete(String(definition[key]));
      }
    }

    cache.set(definition, result);
  }

  // eslint-disable-next-line ts/no-unsafe-type-assertion
  return result as unknown as ReadonlyMap<K, V>;
}
