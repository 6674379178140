// @generated by protoc-gen-es v2.2.3 with parameter "target=dts+js,import_extension=js"
// @generated from file pinup/google_auth/simple/service.proto (package pinup.google_auth.simple, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import { file_google_protobuf_any } from "@bufbuild/protobuf/wkt";

/**
 * Describes the file pinup/google_auth/simple/service.proto.
 */
export const file_pinup_google_auth_simple_service = /*@__PURE__*/
  fileDesc("CiZwaW51cC9nb29nbGVfYXV0aC9zaW1wbGUvc2VydmljZS5wcm90bxIYcGludXAuZ29vZ2xlX2F1dGguc2ltcGxlIh4KDVNpZ25JblJlcXVlc3QSDQoFdG9rZW4YASABKAkiWAoOU2lnbkluUmVzcG9uc2USDwoHcmVmcmVzaBgBIAEoCRIOCgZhY2Nlc3MYAiABKAkSJQoGZXh0cmFzGP8BIAMoCzIULmdvb2dsZS5wcm90b2J1Zi5BbnkycAoRU2ltcGxlQXV0aFNlcnZpY2USWwoGU2lnbkluEicucGludXAuZ29vZ2xlX2F1dGguc2ltcGxlLlNpZ25JblJlcXVlc3QaKC5waW51cC5nb29nbGVfYXV0aC5zaW1wbGUuU2lnbkluUmVzcG9uc2VCNFoyZ2l0LnRpbWUyZ28udGVjaC9hcGlzL2dlbi9waW51cC9nb29nbGUtYXV0aC9zaW1wbGViBnByb3RvMw", [file_google_protobuf_any]);

/**
 * Describes the message pinup.google_auth.simple.SignInRequest.
 * Use `create(SignInRequestSchema)` to create a new message.
 */
export const SignInRequestSchema = /*@__PURE__*/
  messageDesc(file_pinup_google_auth_simple_service, 0);

/**
 * Describes the message pinup.google_auth.simple.SignInResponse.
 * Use `create(SignInResponseSchema)` to create a new message.
 */
export const SignInResponseSchema = /*@__PURE__*/
  messageDesc(file_pinup_google_auth_simple_service, 1);

/**
 * @generated from service pinup.google_auth.simple.SimpleAuthService
 */
export const SimpleAuthService = /*@__PURE__*/
  serviceDesc(file_pinup_google_auth_simple_service, 0);

