import { Envelope } from './envelope.js';
import { ENVELOPE_HEADER_BYTE_LENGTH } from './envelope-header-byte-length.js';

export class EnvelopeDecodeStream extends TransformStream<
  Uint8Array,
  Envelope[]
> {
  private leftovers: Uint8Array | null = null;

  constructor() {
    super({
      transform: (chunk, controller) => {
        const envelops: Envelope[] = [];

        let combinedChunk: Uint8Array;

        if (this.leftovers !== null) {
          combinedChunk = new Uint8Array(
            this.leftovers.byteLength + chunk.byteLength,
          );
          combinedChunk.set(this.leftovers, 0);
          combinedChunk.set(chunk, this.leftovers.byteLength);
        } else {
          combinedChunk = chunk;
        }

        while (combinedChunk.byteLength >= ENVELOPE_HEADER_BYTE_LENGTH) {
          const header = new DataView(
            combinedChunk.buffer,
            combinedChunk.byteOffset,
            ENVELOPE_HEADER_BYTE_LENGTH,
          );
          const envelopeByteLength = header.getUint32(1, false);

          if (
            combinedChunk.byteLength <
            ENVELOPE_HEADER_BYTE_LENGTH + envelopeByteLength
          ) {
            break;
          }

          // TODO extract to fromEnvelopeChunk
          envelops.push(
            new Envelope(
              header.getUint8(0),
              new Uint8Array(
                combinedChunk.buffer,
                combinedChunk.byteOffset + ENVELOPE_HEADER_BYTE_LENGTH,
                envelopeByteLength,
              ),
            ),
          );

          combinedChunk = new Uint8Array(
            combinedChunk.buffer,
            combinedChunk.byteOffset +
              ENVELOPE_HEADER_BYTE_LENGTH +
              envelopeByteLength,
          );
        }

        this.leftovers = combinedChunk;

        if (envelops.length === 0) {
          return;
        }

        controller.enqueue(envelops);
      },
    });
  }
}
