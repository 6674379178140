/* eslint-disable ts/no-magic-numbers */
import { Code } from '@up/grpc';

// TODO Reconsider using some specific statuses like FAILED_PRECONDITION etc.
export function fromHttpStatus(status: number): Code {
  switch (status) {
    case 200:
      return Code.OK;
    case 400:
      return Code.INVALID_ARGUMENT; // FAILED_PRECONDITION / OUT_OF_RANGE
    case 401:
      return Code.UNAUTHENTICATED;
    case 403:
      return Code.PERMISSION_DENIED;
    case 404:
      return Code.NOT_FOUND;
    case 409:
      return Code.ABORTED; // ALREADY_EXISTS
    case 429:
      return Code.RESOURCE_EXHAUSTED;
    case 499:
      return Code.CANCELLED;
    case 500:
      return Code.INTERNAL; // DATA_LOSS
    case 501:
      return Code.UNIMPLEMENTED;
    case 503:
      return Code.UNAVAILABLE;
    case 504:
      return Code.DEADLINE_EXCEEDED;
    default:
      return Code.UNKNOWN;
  }
}
