import type { ApplicationConfig } from '@angular/core';
import {
  inject,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';

import { ENV_GOOGLE_ID, FEATURE_PREFIX } from '@up/env';
import {
  GOOGLE_SIGN_IN_BUTTON_DEFAULT,
  GOOGLE_SIGN_IN_CLIENT_ID,
  provideGoogleSignIn,
} from '@up/google';
import {
  BASE_HREF,
  provideExisting,
  provideFactory,
  provideValue,
} from '@up/ng';

import { SignInService } from './app/sign-in.service.js';

export const baseConfig: ApplicationConfig = {
  providers: [
    provideExperimentalZonelessChangeDetection(),

    provideValue(GOOGLE_SIGN_IN_BUTTON_DEFAULT, {
      type: 'standard',
      theme: 'outline',
      text: 'continue_with',
      width: 300,
    }),
    provideGoogleSignIn({
      callback: ({ credential }) => {
        inject(SignInService).logIn(credential);
      },
    }),
    provideExisting(GOOGLE_SIGN_IN_CLIENT_ID, ENV_GOOGLE_ID),

    provideFactory(FEATURE_PREFIX, () => inject(BASE_HREF).slice(0, -1)),
  ],
};
