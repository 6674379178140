import type {
  DepartmentGroup,
  FrontLiteral,
  LicenseFeature,
  LicenseGroup,
  LicenseLiteral,
} from '#gen/env';
import type { AccessGroup } from './access-group.js';

function findLicense(
  licenses: Iterable<LicenseLiteral>,
  group: LicenseGroup,
): LicenseLiteral {
  for (const license of licenses) {
    if (license.group === group) {
      return license;
    }
  }

  throw new Error(`License ${group} not found`);
}

export class Front {
  static *from(
    rootOrigin: string,
    fronts: readonly FrontLiteral[],
    licenses: readonly LicenseLiteral[],
  ): Iterable<Front, void> {
    for (const {
      title,
      license,
      url,
      departments,
      redirectTo = '',
    } of fronts) {
      const departmentsSet = new Set<DepartmentGroup>(departments);
      if (typeof license === 'string') {
        const { origin, features } = findLicense(licenses, license);

        yield new Front(
          url,
          title,
          origin ?? rootOrigin,
          license,
          departmentsSet,
          new Set<LicenseFeature>(features),
          redirectTo,
        );
      } else {
        for (const group of license) {
          const {
            origin,
            features,
            title: licenseTitle,
            url: licenseUrl = group,
          } = findLicense(licenses, group);

          // eslint-disable-next-line ts/no-use-before-define
          yield new LicenseFront(
            url,
            title,
            origin ?? rootOrigin,
            group,
            departmentsSet,
            new Set<LicenseFeature>(features),
            redirectTo,
            licenseTitle,
            licenseUrl,
          );
        }
      }
    }
  }

  readonly #frontPathSegment: string;
  readonly redirectTo: string;
  readonly #frontTitle: string;
  readonly origin: string;
  readonly license: LicenseGroup;
  readonly departments: ReadonlySet<DepartmentGroup>;
  readonly #features: ReadonlySet<LicenseFeature>;

  // eslint-disable-next-line ts/max-params
  protected constructor(
    frontPathSegment: string,
    frontTitle: string,
    origin: string,
    license: LicenseGroup,
    departments: ReadonlySet<DepartmentGroup>,
    features: ReadonlySet<LicenseFeature>,
    redirectTo: string,
  ) {
    this.#frontPathSegment = frontPathSegment;
    this.#frontTitle = frontTitle;
    this.origin = origin;
    this.license = license;
    this.departments = departments;
    this.#features = features;
    this.redirectTo = redirectTo;
  }

  get baseHref(): string {
    return `/${encodeURIComponent(this.#frontPathSegment)}/`;
  }

  get defaultUrl(): string {
    return `${this.baseHref}${this.redirectTo}`;
  }

  get baseTitle(): string {
    return this.#frontTitle;
  }

  availableIn(group: AccessGroup): boolean {
    return (
      this.license === group.license && this.departments.has(group.department)
    );
  }

  supports(feature: LicenseFeature): boolean {
    return this.#features.has(feature);
  }
}
// class SimpleFront extends Front {}

class LicenseFront extends Front {
  readonly #licensePathSegment: string;
  readonly #licenseTitle: string;

  // eslint-disable-next-line ts/max-params
  constructor(
    url: string,
    title: string,
    origin: string,
    license: LicenseGroup,
    departments: ReadonlySet<DepartmentGroup>,
    features: ReadonlySet<LicenseFeature>,
    redirectTo: string,
    licenseTitle: string,
    licenseUrl: string,
  ) {
    super(url, title, origin, license, departments, features, redirectTo);

    this.#licensePathSegment = licenseUrl;
    this.#licenseTitle = licenseTitle;
  }

  override get baseHref(): string {
    return `${super.baseHref}${encodeURIComponent(this.#licensePathSegment)}/`;
  }

  override get baseTitle(): string {
    return `${super.baseTitle} (${this.#licenseTitle})`;
  }
}
