import type { DescMethod, MessageShape } from '@bufbuild/protobuf';
import type { Observable, TapObserver } from 'rxjs';
import { defer, tap } from 'rxjs';

import { enumValueToFirstKey } from '@up/utils';

import { Code } from '#gen/code';
import type { GrpcContext } from '../transport/grpc-context.js';
import { GrpcError } from '../transport/grpc-error.js';
import { GrpcMessageValue } from '../transport/grpc-message-value.js';
import { GrpcTransport } from '../transport/grpc-transport.js';
import type { GrpcValue } from '../transport/grpc-value.js';

function getErrorName(error: unknown): string {
  return error instanceof Error ? error.name : 'Unknown';
}

function getErrorStatus(error: unknown): string {
  return error instanceof GrpcError
    ? error.toStatusString()
    : getErrorName(error);
}

const ACCURACY = 2;

class LoggerObserver<M extends DescMethod>
  implements
    Pick<
      TapObserver<GrpcValue<M['output']>>,
      'next' | 'unsubscribe' | 'error' | 'complete'
    >
{
  readonly #startTime = performance.now();

  readonly #method: M;
  readonly #input: NoInfer<MessageShape<M['input']>>;
  readonly #outputs: NoInfer<MessageShape<M['output']>>[] = [];

  constructor(method: M, input: NoInfer<MessageShape<M['input']>>) {
    this.#method = method;
    this.#input = input;
  }

  #logGroupStart(status: string, color: string): void {
    const timeElapsed = performance.now() - this.#startTime;

    // TODO log error message
    console.groupCollapsed(
      '%c[gRPC %s]%c️ %s/%c%s%c (%s ms)',
      `color: ${color}`,
      status,
      'font-weight: normal; color: gray',
      this.#method.parent.typeName,
      'font-weight: bold',
      this.#method.name,
      'font-weight: normal',
      timeElapsed.toFixed(ACCURACY),
    );
  }

  #logBody(): void {
    console.log('%cInput%c', 'color: #884ea0', '', this.#input);
    for (const output of this.#outputs) {
      console.log('%cNext%c', 'color: #17a589', '', output);
    }
  }

  next(next: GrpcValue<M['output']>): void {
    if (next instanceof GrpcMessageValue) {
      this.#outputs.push(next.message);
    }
  }

  complete(): void {
    this.#logGroupStart(enumValueToFirstKey(Code, Code.OK), '#00aa00');
    this.#logBody();
    console.groupEnd();
  }

  error(error: unknown): void {
    this.#logGroupStart(getErrorStatus(error), '#ff0000');

    this.#logBody();
    console.log('%cError%c %O', 'color: #17a589', '', error);
    console.groupEnd();
  }

  unsubscribe(): void {
    this.#logGroupStart(enumValueToFirstKey(Code, Code.CANCELLED), '#4444ff');
    this.#logBody();
    console.groupEnd();
  }
}

// TODO log FETCH_ORIGIN
export class LoggerTransport extends GrpcTransport {
  readonly #transport: GrpcTransport;

  constructor(transport: GrpcTransport) {
    super();
    this.#transport = transport;
  }

  lowLevelSummon<M extends DescMethod>(
    method: M,
    input: NoInfer<MessageShape<M['input']>>,
    context: GrpcContext,
  ): Observable<GrpcValue<M['output']>> {
    const result$ = this.#transport.lowLevelSummon<M>(method, input, context);

    return defer(() => result$.pipe(tap(new LoggerObserver(method, input))));
  }
}
