import { InjectionToken } from '@angular/core';

import type { LocalStorageSubject } from '@up/rx/dom';
import { JsonLocalStorageSubject } from '@up/rx/dom';

import { Session } from './session.js';

export const STORED_SESSION = new InjectionToken<
  LocalStorageSubject<Session, null>
>('STORED_SESSION', {
  providedIn: 'root',
  factory: () =>
    new JsonLocalStorageSubject('session', null, Session.fromJson, {
      equal: Session.equal,
    }),
});
