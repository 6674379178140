import { inject, Injectable } from '@angular/core';
import { WA_LOCATION } from '@ng-web-apis/common';
import {
  catchError,
  map,
  type Observable,
  of,
  share,
  shareReplay,
  Subject,
  switchMap,
} from 'rxjs';

import type { Front, RollbackLinkSearchParam } from '@up/env';
import {
  AccessGroup,
  ENV_TRANSPORT,
  FEATURE_PREFIX,
  KNOWN_FRONTS,
  parseAccessJwt,
  Session,
  STORED_SESSION,
} from '@up/env';

import type { SignInRequest } from '#gen/service';
import { SimpleAuthService } from '#gen/service';

interface AvailableRollback {
  readonly type: 'available';
  readonly front: Front;
  readonly url: string;
}

interface UnavailableRollback {
  readonly type: 'unavailable';
  readonly front: Front;
}

interface InvalidRollback {
  readonly type: 'invalid';
  readonly url: string;
}

type UnauthorizedRollback = UnavailableRollback | InvalidRollback;

type AuthorizedRollback =
  | AvailableRollback
  | UnavailableRollback
  | InvalidRollback;

interface NotExistState {
  readonly type: 'not-exist';
}

interface UnauthorizedState {
  readonly type: 'unauthorized';
  readonly rollback: UnauthorizedRollback | null;
}

interface AuthorizedState {
  readonly type: 'authorized';
  readonly rollback: AuthorizedRollback | null;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly picture: string;
  readonly fronts: readonly Front[];
}

interface ErrorState {
  readonly type: 'error';
  readonly error: string;
}

interface RedirectionState {
  readonly type: 'redirection';
  readonly front: Front;
}

type OneOfState =
  | NotExistState
  | UnauthorizedState
  | AuthorizedState
  | ErrorState
  | RedirectionState;

function findFront(fronts: Iterable<Front>, url: string): Front | null {
  for (const front of fronts) {
    if (url.startsWith(front.baseHref)) {
      return front;
    }
  }

  return null;
}

@Injectable({ providedIn: 'root' })
export class SignInService {
  readonly #location = inject(WA_LOCATION);
  readonly #fronts = inject(KNOWN_FRONTS);
  readonly #transport = inject(ENV_TRANSPORT);
  readonly #session$ = inject(STORED_SESSION);
  readonly #prefix = inject(FEATURE_PREFIX);

  readonly #logIn$ = new Subject<
    google.accounts.id.CredentialResponse['credential']
  >();

  readonly state$: Observable<OneOfState> = this.#logIn$.pipe(
    switchMap((token) =>
      this.#transport.summon(SimpleAuthService.method.signIn, { token }),
    ),
    map(({ refresh, access, extras }) => new Session(access, refresh, extras)),
    share({ connector: () => this.#session$ }),
    map((session) => this.#getState(session)),
    catchError((error) => of<ErrorState>({ type: 'error', error })),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  #getState(session: Session | null): OneOfState {
    if (this.#location.pathname !== `${this.#prefix}/`) {
      return { type: 'not-exist' };
    }

    const searchParams = new URLSearchParams(this.#location.search);
    const reloginUrl = searchParams.get(
      'relogin' satisfies RollbackLinkSearchParam,
    );
    const logoutUrl = searchParams.get(
      'logout' satisfies RollbackLinkSearchParam,
    );

    let url: string | null = null;
    let autoLogin = false;

    if (reloginUrl !== null) {
      url = reloginUrl;
      autoLogin = true;
    } else if (logoutUrl !== null) {
      url = logoutUrl;
    }

    if (session === null) {
      let rollback: UnauthorizedRollback | null = null;

      if (url !== null) {
        const front: Front | null = findFront(this.#fronts, url);

        if (front === null) {
          rollback = { type: 'invalid', url };
        } else {
          rollback = { type: 'unavailable', front };
        }
      }

      return { type: 'unauthorized', rollback };
    }

    const {
      groups: payloadGroups,
      first_name: firstName,
      last_name: lastName,
      email,
      picture,
    } = parseAccessJwt(session.access);

    const groups = payloadGroups.map((group) => AccessGroup.parse(group));

    let rollback: AuthorizedRollback | null = null;

    if (url !== null) {
      const front: Front | null = findFront(this.#fronts, url);

      if (front === null) {
        rollback = { type: 'invalid', url };
      } else if (groups.some((group) => front.availableIn(group))) {
        if (autoLogin) {
          this.#location.replace(`${this.#prefix}${url}`);
          return { type: 'redirection', front };
        }

        rollback = { type: 'available', front, url };
      } else {
        rollback = { type: 'unavailable', front };
      }
    }

    const fronts: Front[] = this.#fronts.filter((item) =>
      groups.some((group) => item.availableIn(group)),
    );

    return {
      type: 'authorized',
      rollback,
      firstName,
      lastName,
      email,
      picture,
      fronts,
    };
  }

  logIn(credential: NonNullable<SignInRequest['token']>): void {
    this.#logIn$.next(credential);
  }

  logOut(): void {
    this.#session$.nextDefault();
  }

  goToRollBack(url: string): void {
    this.#location.replace(`${this.#prefix}${url}`);
  }
}
